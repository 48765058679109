import { LogisticOverviewType } from "~/routes/logistic+/overview.$type/constants";

export const SUBMIT_INTENT = "intent";

export const ROUTE_TRADEPRODUCTSHOP = "/trade/shop";
export const ROUTE_TRADEPRODUCT_DETAIL = "/trade/detail/";
export const ROUTE_TRADEPRODUCT_UTP_LOADER = "/trade/tradeproduct/utp/loader";

export const ROUTE_TRADEOVERVIEW_DEFAULT = "/trade/overview/default";
export const ROUTE_TRADEOVERVIEW_DELIVERY = "/trade/overview/delivery";
export const ROUTE_TRADEOVERVIEW_DONE = "/trade/overview/done";

export const ROUTE_TRADEOVERVIEW_DETAILLOADER = "/trade/utp/history/loader";

export const ROUTE_LOGISTIC = "/logistic/requests";
export const ROUTE_LOGISTIC_DETAIL = "/logistic/detail/";
export const ROUTE_LOGISTICOVERVIEW_BASE = "/logistic/overview/";
export const ROUTE_LOGISTICOVERVIEW_MYREQUESTS = `${ROUTE_LOGISTICOVERVIEW_BASE}${LogisticOverviewType.MyRequests}`;
export const ROUTE_LOGISTICOVERVIEW_MYOFFERS = `${ROUTE_LOGISTICOVERVIEW_BASE}${LogisticOverviewType.MyOffers}`;
export const ROUTE_LOGISTICOVERVIEW_DONE = `${ROUTE_LOGISTICOVERVIEW_BASE}${LogisticOverviewType.Done}`;
export const ROUTE_LOGISTICOVERVIEW_PAST = `${ROUTE_LOGISTICOVERVIEW_BASE}${LogisticOverviewType.Past}`;

export const ROUTE_LOGISTICOVERVIEW_HISTORYLOADER =
  "/logistic/offer/history/loader";
export const ROUTE_LOGISTIC_OFFERLOADER =
  "/logistic/transportrequest/offer/loader";
